import { css } from '@emotion/react';
import React from 'react';

export const Pill = ({ title, textColor, bgColor }) => (
  <span
    css={css`
      font-weight: 500;
      color: ${textColor};
      background: ${bgColor};
      border-radius: 99rem;
      height: 1.5rem;
      padding: 0 0.5rem;
      line-height: 1.5rem;
    `}
  >
    {title}
  </span>
)