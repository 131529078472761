import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import NotFoundIcon from '@birdi/icons/2022-not-found.svg';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { CloudHeader } from '../common/Header';

export const NotFoundScreen = (): JSX.Element => (
  <div>
    <CloudHeader />
    <div css={css`margin: auto; margin-top: 8%; width: 20rem; text-align: center;`}>
      <NotFoundIcon css={css`font-size: 17rem;`} />
      <h2
        css={(theme: { [key:string]: string }) => css`
          font-size: 3rem;
          font-weight: 700;
          color: ${theme.midnight};
          line-height: 0;`}
      >
        Uh oh!
      </h2>
      <p css={css`font-size: 1.3rem; margin-bottom: 2.5rem;`}>The page you&apos;re looking for doesn&apos;t exist or has been moved.</p>
      <Link
        to="/"
        css={(theme: BirdiTheme) => css`
          ${buttonBlue(theme)}
          padding: 1rem 3rem;
          border-radius: 8px;
        `}
      >
        Head Back Home
      </Link>
    </div>
  </div>
);
