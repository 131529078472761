import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { css } from '@emotion/react';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import MenuSVG from '@birdi/icons/menu-2.svg';
import CloseSVG from '@birdi/icons/close.svg';
import { ContextMenuHOC } from '@birdi/context-menu/hoc';

const MAX_WIDTH = '414px';

const menu = `
  padding: 0.5rem 0.7rem 0.7rem 0;
  width: 100%;
  display: block;
`;

export const MobileMenu = ContextMenuHOC((props) => (
  <div css={css`${overlayMenuContainerCSS}
      position: absolute;
      top: 0;
      right: 1rem;
      min-width: 275px;
    `}
  >
    <button
      type="button"
      css={css`
        position: absolute;
        right: 0;
        top: 0.5rem;
        display: flex;
        align-items: center;
        border-radius: 2px;
        width: 6rem;
        height: 3rem;
        margin-left: 1rem;
      `}
      ref={props.toggleRef}
      onClick={props.toggle}
    >
      <span>Menu</span>
      <MenuSVG css={(theme) =>css`
          margin-left: 0.5em;
          width: 1.5em;
          height: 1.5em;
          fill: currentColor;
          margin-top: 2px;
          color: ${theme.mono70};
        `}
      />
    </button>
    <CSSTransition
      in={props.open}
      timeout={150}
      classNames="drop-menu"
      unmountOnExit
    >
      <div css={css`
          position: relative;
          left: 1rem;
          width: calc(100% - 2rem);
          background-color: white;
          border-radius: 4px;
          box-shadow: rgba(9,30,66,0.1) 0px 1px 1px, rgba(9,30,66,0.2) 1px 2px 7px -2px;
          @media (max-width: ${MAX_WIDTH}){
            left: 0.2rem;
            width: calc(100% - 0.4rem);
          }
        `}
      >
        <div
          ref={props.wrapperRef}
          css={css`padding-top: 0rem; color: black;`}
        >
          <button
            type="button"
            css={css`
              position: absolute;
              right: 0;
              top: 0;
              width: 3rem;
              height: 3rem;
          `}
            onClick={() => props.hide()}
          >
            <CloseSVG css={css`
                width: 1rem;
                height: 1rem;
              `}
            />
          </button>
          <ul css={css`
            list-style-type: none;
            padding: 1rem;
            a {
              ${menu};
              cursor: pointer;
            }
          `}
          >
            {props.children({ ...props })}
          </ul>
        </div>
      </div>
    </CSSTransition>
  </div>
));
