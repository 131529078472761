import React from 'react';
import AsyncSelect from 'react-select/async';
import { css } from '@emotion/react';
import { debounce } from 'lodash-es';
import { organisation } from '@birdi/js-sdk/src/admin';
import { stroot } from '@birdi/js-sdk';
import { components } from 'react-select';

const formatPredictions = (organisations) => (
  organisations.map((item) => ({
    value: item.id,
    label: item.name,
    domain: item.domain,
    totalUsers: item.totalUsers,
    totalMissions: item.totalMissions,
  }))
)

const OrgOption = (props) => {
  const { isSelected, data } = props;
  return (
    <components.Option {...props}>
      <div>
        <div>{data.label}</div>
        {data.domain && (
          <div css={css`${(!isSelected && "color: #B8B8BA") || ""}`}>
            {data.domain} ({data.totalUsers} user{data.totalUsers === 1 ? '' : 's'})
          </div>
        )}
      </div>
    </components.Option>
  );
};

const OrgSingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <div>
        <div>{data.label}</div>
        {data.domain && (
          <div style={{ color: "grey" }}>{data.domain}</div>
        )}
      </div>
    </components.SingleValue>
  );
};

export const OrganisationSelect = (props) => {
  const {
    placeholder, value, onOrganisationSelect,
    onClear, isClearable, inputId, theme, styles,
    defaultValue,
  } = props;

  const debouncedFetchOrganisations = debounce((searchTerm, callback) => {
      organisation.getSelectionList(stroot('ang5ai'), { search: searchTerm })
        .then((response) => response.body)
        .then((data) => callback(formatPredictions(data)));
  });
  const fetchOrganisations = (name, callback) => {
    if (!name) {
      return Promise.resolve({ options: [] });
    }
    return debouncedFetchOrganisations(name, callback);
  };
  const handleSelect = (selectedName) => {
    if (!selectedName) return onClear();
    onOrganisationSelect(selectedName);
  };
  return (
    <AsyncSelect
      loadOptions={fetchOrganisations}
      onChange={handleSelect}
      placeholder={placeholder}
      isClearable={isClearable}
      value={value}
      defaultValue={defaultValue}
      theme={theme}
      css={css`min-width: 14rem;`}
      inputId={inputId}
      styles={{
        control: (base) => ({
          ...base,
          height: '100%',
        }),
        placeholder: (defaultStyles) => ({
          ...defaultStyles,
          color: '#888',
        }),
        ...styles
      }}
      components={{
        Option: OrgOption,
        SingleValue: OrgSingleValue,
      }}
    />
  );
};
