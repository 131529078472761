/* globals window */

import React from 'react';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';
import { ContextMenuHOC } from '@birdi/context-menu';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import { auth, stroot } from '@birdi/js-sdk';
import SignOutSVG from '@birdi/icons/log-out.svg';
import { BirdiTheme, fonts } from '@birdi/theme';

const avatarButtonCSS = (url: string) => (theme: BirdiTheme) => css`
  background-image: url('${url}');
  background-position: center;
  background-size: cover;
  background-color: ${theme.flora};
  color: white;
  width: 3em;
  height: 3em;
  border-radius: 3em;
`;

const AvatarButtonInternal = ContextMenuHOC(({
  toggleRef, toggle, open, wrapperRef, // Context menu props
  meta, // contextual props
}) => (
  <div css={css(overlayMenuContainerCSS)}>
    <button
      type="button"
      css={avatarButtonCSS(meta.photo)}
      ref={toggleRef}
      onClick={toggle}
    >
      {(!meta.photo && meta.fname) && meta.fname[0]}
    </button>
    <CSSTransition
      in={open}
      timeout={150}
      classNames="drop-menu"
      unmountOnExit
    >
      <div
        css={(theme: BirdiTheme) => css`
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 3.75rem;
          right: 0;
          color: ${theme.midnight};
          background: white;
          box-shadow: rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.16) 0px 0px 1px 0px;
          border-radius: 3px;
          overflow: hidden;
          padding: 0.5em;
          .item {
            padding: 0.75rem;
            white-space: nowrap;
            text-align: left;
            &:hover {
              background: ${theme.mono20};
            }
          }`}
        ref={wrapperRef}
      >
        <button
          className="item"
          type="button"
          onClick={() => {
            auth.clearCookie(stroot('bsdzx3'));
          }}
        >
          <SignOutSVG css={css`
              stroke-width: 1.5px;
              vertical-align: middle;
              margin-right: 4px;
            `}
          />
          <span css={(theme: BirdiTheme) => css`
            vertical-align: middle;
            font-family: ${fonts.display};
            color: ${theme.midnight};
          `}
          >
            Sign Out
          </span>
        </button>
      </div>
    </CSSTransition>
  </div>
));

export const AvatarButton = connect((state) => ({
  meta: state.meta,
}))(AvatarButtonInternal);
