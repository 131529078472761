/* global */
import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import {
  Link, LinkProps, useHistory, withRouter,
} from 'react-router-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { SignOutButton } from '@birdi/main-navigation/SignOut';
import { MobileMenu as MobileMenuBase } from '@birdi/main-navigation/MobileMenu';
import { BirdiTheme } from '@birdi/theme';
import { User } from '@birdi/types';
import BirdiIcon, { Icons } from '@birdi/icons';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import { hasPermission } from '@birdi/utils/src/helpers';
import { Pill } from '@birdi/shared-deprecated/pill';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { useMetaSelector } from '../../meta/reducer';

type MenuItemLinkProps = LinkProps & {
  match: string;
  missions?: boolean;
  hide: () => void;
};

const MenuItemLink: React.FC<MenuItemLinkProps> = ({
  to, match, children, missions = false, className = '', hide, id = null,
}) => {
  const otherProps: Partial<LinkProps> = {};
  if (id) otherProps.id = id;
  return (
    <Link
      className={cx(className, { selected: to === match && !missions })}
      to={to}
      onClick={() => { hide(); }}
      {...otherProps}
    >
      {children}
    </Link>
  );
};
MenuItemLink.defaultProps = { missions: false };

const lineStyle = (theme: BirdiTheme) => css`
  border: none;
  border-top: 1px solid ${theme.mono20};
`;

export const MobileMenu = () => {
  const meta = useMetaSelector();
  const history = useHistory();
  const appTheme = useTheme();
  const [expandedMenuItem, setExpandedMenuItem] = useState({
    missions: false,
    inspection: false,
    processing: false,
    companyInfo: false,
    billing: false,
    settings: false,
    help: false,
  });
  const manageUsers = meta.organisation && hasPermission(meta.permissions, 'org:manage-users');
  const inspection = meta.orgFeatureFlags.includes(FeatureFlagNames.PowercorInspectionDashboard)
    && meta.permissions.includes('org:manage-missions');
  const costCalculator = meta.orgFeatureFlags.includes(FeatureFlagNames.GigapixelCalculator);
  const customBranding = meta.orgFeatureFlags.includes(FeatureFlagNames.CustomBranding);
  const canViewProcessing = hasPermission(meta.permissions, 'org:manage-missions');
  const sites = meta.orgFeatureFlags.includes(FeatureFlagNames.Sites);
  return (
    <MobileMenuBase>
      {({ hide }) => (
        <div css={(theme: BirdiTheme) => css`
            .item {
              display: flex;
              width: 100%;
              color: ${theme.displayFont};
              padding: 0.5em;
              font-size: 0.9375em;
              align-items: center;
              gap: 0.5rem;
              &.selected {
                color: ${theme.deepOcean};
              }
              &.main {
                font-size: 1em;
                font-weight: 700;
              }
              &.link-disabled {
                pointer-events: none;
                color: ${theme.mono40};
              }
              .icon-chevron {
                margin-left: auto;
              }
            }
          `}
        >
          <MenuItemLink to="/" match={history.location.pathname} hide={hide} className={cx('item', 'main')}>
            <span>Dashboard</span>
          </MenuItemLink>
          <hr className="divider" />
          <button
            className={cx('item', 'main')}
            type="button"
            onClick={() => setExpandedMenuItem((prev) => ({ ...prev, missions: !prev.missions }))}
            css={css`padding: 0;`}
          >
            <span>Missions</span>
            <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.missions ? '180' : '90'} />
          </button>
          <div css={css`${overlayMenuContainerCSS}`}>
            <CSSTransition
              in={expandedMenuItem.missions}
              timeout={150}
              classNames="drop-menu"
              unmountOnExit
            >
              <div>
                {meta.jobNotifications && (
                  <MenuItemLink hide={hide} to="/job-requests/incoming" match={history.location.pathname} className={cx('item', 'sub')}>
                    <span>Capture Requests</span>
                  </MenuItemLink>
                )}
                {meta.permissions.includes('org:default') && (
                  <MenuItemLink hide={hide} to="/missions/mine" match={history.location.pathname} className={cx('item', 'sub')}>
                    <span>My Missions</span>
                  </MenuItemLink>
                )}
                <MenuItemLink hide={hide} to="/missions/shared" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>Shared With Me</span>
                </MenuItemLink>
                <MenuItemLink hide={hide} to="/missions/organisation" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>All Missions</span>
                </MenuItemLink>
              </div>
            </CSSTransition>
          </div>
          <hr className="divider" />
          {sites && (
            <>
              <MenuItemLink to="/sites" match={history.location.pathname} hide={hide} className={cx('item', 'main')}>
                <span>Sites</span>
              </MenuItemLink>
              <hr className="divider" />
            </>
          )}
          <button
            className={cx('item', 'main')}
            type="button"
            onClick={() => setExpandedMenuItem((prev) =>
              ({ ...prev, inspection: !prev.inspection }))}
            css={css`padding: 0;`}
          >
            <span>Inspection</span>
            <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.inspection ? '180' : '90'} />
          </button>
          <div css={css`${overlayMenuContainerCSS}`}>
            <CSSTransition
              in={expandedMenuItem.inspection}
              timeout={150}
              classNames="drop-menu"
              unmountOnExit
            >
              <div>
                <MenuItemLink id="assessment-types" hide={hide} to="/assessment-types" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span css={css`margin-right: 0.25rem;`}>Assessment Types</span>
                </MenuItemLink>
                <MenuItemLink id="assessment-severity" hide={hide} to="/assessment-severity" match={history.location.pathname} className={cx('item', 'sub', 'severity-scale')}>
                  <span>Assessment Severity</span>
                </MenuItemLink>
                <MenuItemLink hide={hide} to={inspection ? '/inspections' : '#'} match={history.location.pathname} className={cx('item', 'sub', { 'link-disabled': !inspection })}>
                  <span id="inspection-summary">Inspection Summary</span>
                </MenuItemLink>
              </div>
            </CSSTransition>
          </div>
          {canViewProcessing && (
            <>
              <hr className="divider" />
              <button
                className={cx('item', 'main')}
                type="button"
                onClick={() =>
                  setExpandedMenuItem((prev) => ({ ...prev, processing: !prev.processing }))}
                css={css`padding: 0;`}
              >
                <span>Processing</span>
                <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.processing ? '180' : '90'} />
              </button>
              <div css={css`${overlayMenuContainerCSS}`}>
                <CSSTransition
                  in={expandedMenuItem.processing}
                  timeout={150}
                  classNames="drop-menu"
                  unmountOnExit
                >
                  <div>
                    <MenuItemLink hide={hide} to="/processing" match={history.location.pathname} className={cx('item', 'sub')}>
                      <span>Processing Dashboard</span>
                      <Pill title="Trial" textColor={appTheme.midnight} bgColor={appTheme.lightMidnight} />
                    </MenuItemLink>
                    {costCalculator && (
                      <MenuItemLink hide={hide} to="/cost-calculator" match={history.location.pathname} className={cx('item', 'sub')}>
                        <span>Processing Cost Calculator</span>
                      </MenuItemLink>
                    )}
                  </div>
                </CSSTransition>
              </div>
            </>
          )}
          <hr className="divider" />
          <button
            className={cx('item', 'main')}
            type="button"
            onClick={() =>
              setExpandedMenuItem((prev) => ({ ...prev, companyInfo: !prev.companyInfo }))}
            css={css`padding: 0;`}
          >
            <span>Company Information</span>
            <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.companyInfo ? '180' : '90'} />
          </button>
          <div css={css`${overlayMenuContainerCSS}`}>
            <CSSTransition
              in={expandedMenuItem.companyInfo}
              timeout={150}
              classNames="drop-menu"
              unmountOnExit
            >
              <div>
                <MenuItemLink hide={hide} to="/user/details" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>Account</span>
                </MenuItemLink>
                {manageUsers && (
                  <MenuItemLink hide={hide} to="/users" match={history.location.pathname} className={cx('item', 'sub')}>
                    <span>User Management</span>
                  </MenuItemLink>
                )}
                <MenuItemLink hide={hide} to="/equipment" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>Equipment</span>
                </MenuItemLink>
                <MenuItemLink hide={hide} to="/user/credentials" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>Credentials</span>
                </MenuItemLink>
              </div>
            </CSSTransition>
          </div>
          {meta.permissions.includes('org:manage-org') && (
            <>
              <hr className="divider" />
              <button
                className={cx('item', 'main')}
                type="button"
                onClick={() =>
                  setExpandedMenuItem((prev) => ({ ...prev, billing: !prev.billing }))}
                css={css`padding: 0;`}
              >
                <span>Billing & Subscription</span>
                <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.billing ? '180' : '90'} />
              </button>
              <CSSTransition
                in={expandedMenuItem.billing}
                timeout={150}
                classNames="drop-menu"
                unmountOnExit
              >
                <div>
                  {meta.organisation.plan && (
                    <MenuItemLink
                      hide={hide}
                      to="/billing/plan"
                      match={history.location.pathname}
                      className={cx('item', 'sub')}
                    >
                      <span>Plan Overview</span>
                    </MenuItemLink>
                  )}
                  {(!meta.organisation.plan && !meta.organisation.externalBilling) && (
                    <MenuItemLink
                      hide={hide}
                      to="/billing/options"
                      match={history.location.pathname}
                      className={cx('item', 'sub')}
                    >
                      <span>Plan Overview</span>
                    </MenuItemLink>
                  )}
                </div>
              </CSSTransition>
              <hr className="divider" />
              <button
                className={cx('item', 'main')}
                type="button"
                onClick={() =>
                  setExpandedMenuItem((prev) => ({ ...prev, settings: !prev.settings }))}
                css={css`padding: 0;`}
              >
                <span>Settings</span>
                <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.settings ? '180' : '90'} />
              </button>
              <div css={css`${overlayMenuContainerCSS}`}>
                <CSSTransition
                  in={expandedMenuItem.settings}
                  timeout={150}
                  classNames="drop-menu"
                  unmountOnExit
                >
                  <div>
                    <MenuItemLink hide={hide} to="/settings/customisation/branding" match={history.location.pathname} className={cx('item', 'sub', { 'link-disabled': !customBranding })}>
                      <span id="custom-branding">Customization</span>
                    </MenuItemLink>
                  </div>
                </CSSTransition>
              </div>
            </>
          )}
          <hr className="divider" />
          <button
            className={cx('item', 'main')}
            type="button"
            onClick={() =>
              setExpandedMenuItem((prev) => ({ ...prev, help: !prev.help }))}
            css={css`padding: 0;`}
          >
            <span>Help</span>
            <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.help ? '180' : '90'} />
          </button>
          <CSSTransition
            in={expandedMenuItem.help}
            timeout={150}
            classNames="drop-menu"
            unmountOnExit
          >
            <div>
              <Link to={{ pathname: 'https://help.birdi.io/' }} target="_blank" className={cx('item', 'sub')}>
                <span>Knowledge Base</span>
                <ExternalLinkSVG />
              </Link>
              <Link to={{ pathname: 'https://www.birdi.io/terms-and-conditions' }} target="_blank" className={cx('item', 'sub')}>
                <span>Terms & Conditions</span>
                <ExternalLinkSVG />
              </Link>
            </div>
          </CSSTransition>
          <hr css={lineStyle} />
          <SignOutButton />
        </div>
      )}
    </MobileMenuBase>
  );
};
